/********** region Transitioni per le notifiche */

@-webkit-keyframes mountNotification {
    0% {
        opacity: 0;
        -webkit-transform: translateX(400px);
                transform: translateX(400px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes mountNotification {
    0% {
        opacity: 0;
        -webkit-transform: translateX(400px);
                transform: translateX(400px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@-webkit-keyframes unmountNotification {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(400px);
                transform: translateX(400px);
    }
}

@keyframes unmountNotification {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(400px);
                transform: translateX(400px);
    }
}

.notification-enter {
    opacity: 0;
    -webkit-animation: mountNotification 0.3s;
            animation: mountNotification 0.3s;
}

.notification-enter-active {
    opacity: 1;
}

.notification-exit {
    opacity: 1;
    -webkit-animation: unmountNotification 0.3s;
            animation: unmountNotification 0.3s;
}

.notification-exit-active {
    opacity: 0;
}

/********** endregion */

/********** region Transitioni per le icon */

@-webkit-keyframes mountIcon {
    0% {
        opacity: 0;
        -webkit-transform: scale(0) rotate(0deg);
                transform: scale(0) rotate(0deg);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1) rotate(360deg);
                transform: scale(1) rotate(360deg);
    }
}

@keyframes mountIcon {
    0% {
        opacity: 0;
        -webkit-transform: scale(0) rotate(0deg);
                transform: scale(0) rotate(0deg);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1) rotate(360deg);
                transform: scale(1) rotate(360deg);
    }
}

@-webkit-keyframes unmountIcon {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}

@keyframes unmountIcon {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}

.icon-enter {
    opacity: 0;
    -webkit-animation: mountIcon 0.3s;
            animation: mountIcon 0.3s;
}

.icon-enter-active {
    opacity: 1;
}

.icon-exit {
    opacity: 1;
    -webkit-animation: unmountIcon 0.3s;
            animation: unmountIcon 0.3s;
}

.icon-exit-active {
    opacity: 0;
}

/********** endregion */

/********** region Transitioni per il circle widget */

@-webkit-keyframes mountCircle {
    0% {
        opacity: 0;
        -webkit-transform: translateX(0) scale(0.1);
                transform: translateX(0) scale(0.1);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(87) scale(1);
                transform: translateX(87) scale(1);
    }
}

@keyframes mountCircle {
    0% {
        opacity: 0;
        -webkit-transform: translateX(0) scale(0.1);
                transform: translateX(0) scale(0.1);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(87) scale(1);
                transform: translateX(87) scale(1);
    }
}

@-webkit-keyframes unmountCircle {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(382px);
                transform: translateX(382px);
    }
}

@keyframes unmountCircle {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(382px);
                transform: translateX(382px);
    }
}

.circle-enter {
    opacity: 0;
    -webkit-animation: mountCircle 0.3s;
            animation: mountCircle 0.3s;
}

.circle-enter-active {
    opacity: 1;
}

.circle-exit {
    opacity: 1;
    -webkit-animation: unmountCircle 0.3s;
            animation: unmountCircle 0.3s;
}

.circle-exit-active {
    opacity: 0;
}

/********** endregion */

/********** region Transitioni per il layout */

@-webkit-keyframes mountLayout {
    0% {
        opacity: 0;
        -webkit-transform: translateY(50px);
                transform: translateY(50px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes mountLayout {
    0% {
        opacity: 0;
        -webkit-transform: translateY(50px);
                transform: translateY(50px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@-webkit-keyframes unmountLayout {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0) ;
                transform: translateY(0) ;
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(50px);
                transform: translateY(50px);
    }
}

@keyframes unmountLayout {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0) ;
                transform: translateY(0) ;
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(50px);
                transform: translateY(50px);
    }
}

.layout-enter {
    opacity: 0;
    -webkit-animation: mountLayout 0.3s;
            animation: mountLayout 0.3s;
}

.layout-enter-active {
    opacity: 1;
}

.layout-exit {
    opacity: 1;
    -webkit-animation: unmountLayout 0.3s;
            animation: unmountLayout 0.3s;
}

.layout-exit-active {
    opacity: 0;
}

/********** endregion */




#bytewise-oc-chat img {
    all: unset;
}
