/********** region Transitioni per le notifiche */

@keyframes mountNotification {
    0% {
        opacity: 0;
        transform: translateX(400px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes unmountNotification {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(400px);
    }
}

.notification-enter {
    opacity: 0;
    animation: mountNotification 0.3s;
}

.notification-enter-active {
    opacity: 1;
}

.notification-exit {
    opacity: 1;
    animation: unmountNotification 0.3s;
}

.notification-exit-active {
    opacity: 0;
}

/********** endregion */

/********** region Transitioni per le icon */

@keyframes mountIcon {
    0% {
        opacity: 0;
        transform: scale(0) rotate(0deg);
    }
    100% {
        opacity: 1;
        transform: scale(1) rotate(360deg);
    }
}

@keyframes unmountIcon {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0);
    }
}

.icon-enter {
    opacity: 0;
    animation: mountIcon 0.3s;
}

.icon-enter-active {
    opacity: 1;
}

.icon-exit {
    opacity: 1;
    animation: unmountIcon 0.3s;
}

.icon-exit-active {
    opacity: 0;
}

/********** endregion */

/********** region Transitioni per il circle widget */

@keyframes mountCircle {
    0% {
        opacity: 0;
        transform: translateX(0) scale(0.1);
    }
    100% {
        opacity: 1;
        transform: translateX(87) scale(1);
    }
}

@keyframes unmountCircle {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(382px);
    }
}

.circle-enter {
    opacity: 0;
    animation: mountCircle 0.3s;
}

.circle-enter-active {
    opacity: 1;
}

.circle-exit {
    opacity: 1;
    animation: unmountCircle 0.3s;
}

.circle-exit-active {
    opacity: 0;
}

/********** endregion */

/********** region Transitioni per il layout */

@keyframes mountLayout {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes unmountLayout {
    0% {
        opacity: 1;
        transform: translateY(0) ;
    }
    100% {
        opacity: 0;
        transform: translateY(50px);
    }
}

.layout-enter {
    opacity: 0;
    animation: mountLayout 0.3s;
}

.layout-enter-active {
    opacity: 1;
}

.layout-exit {
    opacity: 1;
    animation: unmountLayout 0.3s;
}

.layout-exit-active {
    opacity: 0;
}

/********** endregion */



